import React from 'react';
import {
  ContentWrapper,
  StepWrapper,
  BackButtonWrapper,
  BackButtonBackground,
  BackButtonText,
  NextButtonWrapper,
  BackButtonContainer,
} from './styles';
import {Stepper} from '../../ui-kit/Stepper';
import {Action, AddValidatorState} from '../../hooks/validator';
import {Button} from '../../ui-kit/Buttons/Button';
import {route} from '../../constants/route';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {DataCenterStep} from './DataCenterStep';
import {RegionStep} from './RegionStep';
import {CardStep} from './CardStep';
import {ComfirmStep} from './ComfirmStep';
import {KysNotification} from './KysNotification';
import {User} from '../../types/user';
import {Layout} from '../../ui-kit/Layout';
import {PaymentVariantStep} from './PaymentVariantStep';
import {Options} from '../../ui-kit/Form/Select/types';
import {PaymentsData} from '../../queries/validator';
import {web3} from '../../hooks/metamask';

export const DataCenterAssets = [
  {
    label: 'AWS',
    value: 'aws',
    image: IconSvg({type: 'aws', width: '114', height: '69', viewBox: '0 0 114 69'}),
    offImage: IconSvg({type: 'awsOff', width: '114', height: '68', viewBox: '0 0 114 68'}),
  },
  {
    label: 'Hcloud',
    value: 'hcloud',
    image: IconSvg({type: 'hcloud', width: '100', height: '100', viewBox: '0 0 100 100'}),
    offImage: IconSvg({type: 'hcloudOff', width: '100', height: '100', viewBox: '0 0 100 100'}),
  },
  {
    label: 'Digital Ocean',
    value: 'do',
    image: IconSvg({type: 'digital', width: '127', height: '97', viewBox: '0 0 127 97'}),
    offImage: IconSvg({type: 'digitalOff', width: '127', height: '97', viewBox: '0 0 127 97'}),
  },
  // {
  //   label: 'Coming Soon',
  //   image: IconSvg({type: 'alibaba', width: '136', height: '78', viewBox: '0 0 136 78'}),
  //   offImage: IconSvg({type: 'alibabaOff', width: '136', height: '77', viewBox: '0 0 136 77'}),
  // },
];

interface Props {
  step: number;
  handlerChangeStep: (operation: 'plus' | 'minus' | 'setCurrent') => () => void;
  stepValidation: boolean;
  values: AddValidatorState;
  actionsOfValues: Action;
  userState: User | null;
  zoneData: {name: string; options: {value: string; label: string}[]}[] | null;
  cardList: {value: string; label: string}[] | null;
  addValidationRequest: () => Promise<void>;
  paymentList: PaymentsData[] | null;
}

export const AddValidationForm: React.FC<Props> = ({
  zoneData,
  actionsOfValues,
  cardList,
  addValidationRequest,
  values,
  step,
  stepValidation,
  userState,
  handlerChangeStep,
  paymentList,
}) => {
  const {dataCenter, cardData, cardType, zone, region, checkTerms, paymentMethod} = values;
  const parseSelect = paymentMethod?.split('_');
  const symbol = parseSelect[parseSelect.length - 1]?.toUpperCase() || '';
  const selectedFromList =
    paymentList?.filter((item) => {
      const parse = item.label.split(' ')?.[1];
      return symbol === parse;
    })?.[0]?.label || '';

  const amount = web3.utils.fromWei(
    paymentList?.filter((item) => item.value === paymentMethod)?.[0]?.amount || '0',
    'ether',
  );
  const paymentListOptions = (paymentList?.map((item) => {
    return {label: item.label, value: item.value};
  }) || []) as Options[];

  return (
    <Layout>
      <ContentWrapper>
        {(userState?.isVerified && (
          <>
            {step === 1 && (
              <BackButtonContainer to={route.earn}>
                <BackButtonBackground>
                  <BackButtonWrapper>
                    <IconSvg type="arrowLeft" width="9" height="16" viewBox="0 0 9 16" />
                    <BackButtonText>Back</BackButtonText>
                  </BackButtonWrapper>
                </BackButtonBackground>
              </BackButtonContainer>
            )}
            <Stepper active={step} count={5} setStep={handlerChangeStep('setCurrent')} />
            <StepWrapper>Step{' ' + step}</StepWrapper>
            {step === 1 && (
              <DataCenterStep
                chooseDataCenter={actionsOfValues('chooseDataCenter')}
                dataCenter={dataCenter}
                backStep={handlerChangeStep('minus')}
              />
            )}
            {step === 2 && (
              <RegionStep
                dataCenter={dataCenter}
                backStep={handlerChangeStep('minus')}
                zoneData={zoneData}
                region={region}
                chooseRegionAndZone={actionsOfValues('chooseRegionAndZone')}
              />
            )}
            {step === 3 && (
              <CardStep
                cardType={cardType}
                cardData={cardData}
                selectCard={actionsOfValues('selectCard')}
                setCardData={actionsOfValues('setCardData')}
                cardList={cardList}
                setTypeCard={actionsOfValues('setTypeCard')}
                backStep={handlerChangeStep('minus')}
              />
            )}
            {step === 4 && (
              <PaymentVariantStep
                selectOption={selectedFromList}
                choosePaymentMethod={actionsOfValues('setPaymentMethod')}
                paymentList={paymentListOptions}
                backStep={handlerChangeStep('minus')}
              />
            )}
            {step === 5 && (
              <ComfirmStep
                amount={amount}
                symbol={symbol}
                zoneData={zoneData}
                region={region}
                backStep={handlerChangeStep('minus')}
                dataCenter={dataCenter}
                zone={zone}
                addValidationRequest={addValidationRequest}
                cardData={cardData}
                checkTerms={checkTerms}
                handlerCheckTerms={actionsOfValues('checkTerms')}
              />
            )}
            {step !== 5 && (
              <NextButtonWrapper>
                <Button type={'primary'} onClick={handlerChangeStep('plus')} disabled={!stepValidation}>
                  Next
                </Button>
              </NextButtonWrapper>
            )}
          </>
        )) ||
          (userState && <KysNotification userState={userState} />)}
      </ContentWrapper>
    </Layout>
  );
};
